// extracted by mini-css-extract-plugin
export var About = "index-module--About--Jwoxh";
export var About_Container = "index-module--About_Container--KWF7J";
export var About_Description = "index-module--About_Description--72d6u";
export var About_Green = "index-module--About_Green--stOKV";
export var About_Heading = "index-module--About_Heading--JS5pp";
export var About_HeadingLogo = "index-module--About_HeadingLogo--U0OIj";
export var About_HeadingNewLine = "index-module--About_HeadingNewLine--5MCGs";
export var About_Image = "index-module--About_Image--MxfzD";
export var About_Left = "index-module--About_Left--0qvuI";
export var About_Poem = "index-module--About_Poem--mBi9O";
export var About_Right = "index-module--About_Right--jIACy";
export var Achievement = "index-module--Achievement--rfaKw";
export var Achievement_Second = "index-module--Achievement_Second--dPDJG";
export var Benefit = "index-module--Benefit--+5a87";
export var Benefit_Description = "index-module--Benefit_Description--3RzxV";
export var Benefit_Green = "index-module--Benefit_Green--jugfF";
export var Benefit_Heading = "index-module--Benefit_Heading--toM2k";
export var Benefit_Image = "index-module--Benefit_Image--OTthl";
export var Benefit_Item = "index-module--Benefit_Item--SK6R8";
export var Benefit_ItemHeading = "index-module--Benefit_ItemHeading--8nyNu";
export var Benefit_ItemReverse = "index-module--Benefit_ItemReverse--95ETj";
export var Benefit_NewLine = "index-module--Benefit_NewLine--ber7h";
export var Benefit_Text = "index-module--Benefit_Text--NBqEF";
export var Content = "index-module--Content--mp5Fk";
export var Content_Description = "index-module--Content_Description--azqlT";
export var Content_Item = "index-module--Content_Item--tCR7b";
export var Content_List = "index-module--Content_List--v5AfA";
export var Content_Title = "index-module--Content_Title--gYrVI";
export var Content_Wrapper = "index-module--Content_Wrapper--9uzGr";
export var Feature = "index-module--Feature--9+drz";
export var Feature_Bold = "index-module--Feature_Bold--vqtZa";
export var Feature_ChallengeItem = "index-module--Feature_ChallengeItem--vtzF+";
export var Feature_Challenges = "index-module--Feature_Challenges--IV2JM";
export var Feature_Description = "index-module--Feature_Description--Z-0tQ";
export var Feature_Heading = "index-module--Feature_Heading--vkM+d";
export var Feature_Image = "index-module--Feature_Image--63T7+";
export var Feature_Patent = "index-module--Feature_Patent--5RDuV";
export var Feature_Title = "index-module--Feature_Title--RMKKp";
export var FirstView = "index-module--FirstView--jnQ4N";
export var FirstView_BackgroundImage = "index-module--FirstView_BackgroundImage--1fnt2";
export var FirstView_Container = "index-module--FirstView_Container--XBINc";
export var FirstView_Copy = "index-module--FirstView_Copy--B2Zeu";
export var FirstView_CopyNewLine = "index-module--FirstView_CopyNewLine--4tYTW";
export var FirstView_CopyWrapper = "index-module--FirstView_CopyWrapper--UUoQo";
export var FirstView_Cta = "index-module--FirstView_Cta--h4mnf";
export var FirstView_CtaSection = "index-module--FirstView_CtaSection--mMcdY";
export var FirstView_Description = "index-module--FirstView_Description--4U23u";
export var FirstView_Mock = "index-module--FirstView_Mock--qZGb4";
export var FirstView_MockCaption = "index-module--FirstView_MockCaption--LoAyU";
export var FirstView_MockWrapper = "index-module--FirstView_MockWrapper--epjRu";
export var FirstView_TransparentBlack = "index-module--FirstView_TransparentBlack---prXj";
export var Interview = "index-module--Interview--vM4n2";
export var Interview_Article = "index-module--Interview_Article--KAqNW";
export var Interview_Button = "index-module--Interview_Button--aXGOQ";
export var Interview_ButtonArea = "index-module--Interview_ButtonArea--9-meM";
export var Interview_Description = "index-module--Interview_Description--kyLlw";
export var Interview_Heading = "index-module--Interview_Heading--MJ0Wa";
export var KenroCommon2PaneSectionLayout = "index-module--KenroCommon2PaneSectionLayout--GGJpD";
export var News = "index-module--News--H6FSf";
export var News_Date = "index-module--News_Date--JfU+o";
export var News_Heading = "index-module--News_Heading--zY17g";
export var News_Item = "index-module--News_Item--+oMni";
export var News_Title = "index-module--News_Title--cEP22";
export var Vendor = "index-module--Vendor--comJh";
export var Vendor_Container = "index-module--Vendor_Container--mIMrd";
export var Vendor_Description = "index-module--Vendor_Description--VHzu0";
export var Vendor_Heading = "index-module--Vendor_Heading--1Xsbb";
export var Vendor_Item = "index-module--Vendor_Item--OjnEO";
export var Vendor_LinkToTop = "index-module--Vendor_LinkToTop--BuAsC";
export var Vendor_Logo = "index-module--Vendor_Logo--otG+u";
export var Vendor_LogoWrapper = "index-module--Vendor_LogoWrapper--I7qD5";
export var Vendor_Ribbon = "index-module--Vendor_Ribbon--2sBtL";
export var Vendor_RibbonEdgeAfter = "index-module--Vendor_RibbonEdgeAfter--lQDRh";
export var Vendor_RibbonEdgeBefore = "index-module--Vendor_RibbonEdgeBefore--bolx7";
export var Vendor_SubHeading = "index-module--Vendor_SubHeading--wuUrY";
export var Vendor_Takashi = "index-module--Vendor_Takashi--urJz1";
export var Vendor_TakashiPhoto = "index-module--Vendor_TakashiPhoto--TjScn";