import React from "react";
import { graphql, Link } from "gatsby";
import { Button } from "~components/utils/button";
import { MetaProps } from "~components/templates/seo";
import * as styles from "./index.module.scss";
import { KenroVoiceList } from "~components/kenro/voice-list";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { KenroAchievements } from "~components/kenro/achievements";

type Props = {
  data: GatsbyKenroPageQuery;
};

const Component: React.FC<Props & { className?: string }> = ({ ...props }) => {
  const { data } = props;
  const meta: MetaProps = {
    title:
      "KENRO (ケンロー) | セキュアコーディングを当たり前にするエンジニアの学習プラットフォーム",
    image: `https://flatt.tech${data?.ogp?.publicURL || ""}`,
    description:
      "KENRO (ケンロー) は、Web 開発に必要なセキュリティ技術を体系的に学べる、開発者のための学習プラットフォームです。",
    isSeparateSite: true,
    useTypeSquareFonts: true,
  };

  return (
    <Kenro2PaneLayout meta={meta}>
      <div className={styles.FirstView}>
        <img
          className={styles.FirstView_BackgroundImage}
          src={data?.firstview_background?.publicURL || ""}
          alt=""
        />
        <div className={styles.FirstView_TransparentBlack} />
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.FirstView_Container}>
            <div className={styles.FirstView_CopyWrapper}>
              <h2 className={styles.FirstView_Copy}>
                脆弱性の攻撃と修正で学ぶ
                <br />
                開発者のための
                <br />
                セキュリティ学習サービス
              </h2>
              <p className={styles.FirstView_Description}>
                Web
                開発に必須なセキュリティ技術を、豊富な実践演習で体系的に学べるクラウドサービスです。
              </p>
            </div>
            <div className={styles.FirstView_MockWrapper}>
              <img
                className={styles.FirstView_Mock}
                src={data?.firstview_mock?.publicURL || ""}
                alt=""
              />
              <p className={styles.FirstView_MockCaption}>
                ※ソースコードに含まれた脆弱性の修正の程度を
                <br />
                評価することが可能なサーバー装置に関する特許
              </p>
            </div>
            <div className={styles.FirstView_CtaSection}>
              <a href="/kenro/download/service">
                <Button
                  buttonTheme="Kenro_Download"
                  className={styles.FirstView_Cta}
                  type="button"
                  buttonSize="Medium"
                >
                  資料をダウンロード (無料)
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Achievement}>
        <KenroAchievements type="six" />
      </div>
      <div className={styles.Achievement_second}>
        <KenroAchievements type="twelve" />
      </div>
      <div className={styles.About}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.About_Container}>
            <div className={styles.About_Left}>
              <div>
                <h2 className={styles.About_Heading}>
                  <img
                    className={styles.About_HeadingLogo}
                    src={data?.logo_color?.publicURL || ""}
                    alt=""
                  />
                  <span>は</span>
                  <br />
                  ハンズオン演習に特化した
                  <br className={styles.About_HeadingNewLine} />
                  <span className={styles.About_Green}>
                    実践的なセキュリティ学習
                  </span>
                  <br className={styles.About_HeadingNewLine} />
                </h2>
                <p className={styles.About_Description}>
                  KENROの実践的な学習は、集合研修では定着しづらく、動画やクイズだけのeラーニングでは実践的な理解がしづらい、という課題を解決します。
                  <br />
                  <span className={styles.About_Poem}>
                    KENRO（ケンロー）で、エンジニア組織を堅牢に。
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.About_Right}>
              <img
                className={styles.About_Image}
                src={data?.about?.publicURL || ""}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Benefit}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Benefit_Heading}>KENROが選ばれる3つの理由</h2>
          <div className={styles.Benefit_Item}>
            <img
              className={styles.Benefit_Image}
              src={data?.benefit1?.publicURL || ""}
              alt=""
            />
            <div className={styles.Benefit_Text}>
              <h3 className={styles.Benefit_ItemHeading}>
                開発者のためにつくられた
                <br className={styles.Benefit_NewLine} />
                攻撃とコードの修正で学ぶ
                <span className={styles.Benefit_Green}>実践型演習</span>
              </h3>
              <p className={styles.Benefit_Description}>
                OWASP Top 10
                に含まれる脆弱性をはじめ、Webアプリケーション開発で特に意識すべき脆弱性を学ぶことが出来ます。
              </p>
              <p className={styles.Benefit_Description}>
                実際にWebアプリケーションを攻撃するCTF 形式の「
                <b>ハッキング演習</b>
                」と、コードを書いて脆弱性を修正する「<b>堅牢化演習</b>
                」を通じて、開発現場ですぐに活かせる学びを提供します。
              </p>
            </div>
          </div>
          <div className={styles.Benefit_ItemReverse}>
            <img
              className={styles.Benefit_Image}
              src={data?.languages?.publicURL || ""}
              alt=""
            />
            <div className={styles.Benefit_Text}>
              <h3 className={styles.Benefit_ItemHeading}>
                脆弱性の知識を
                <span className={styles.Benefit_Green}>基礎から体系的に。</span>
                <br />
                いつもの言語やフレームワークで学べます。
              </h3>
              <p className={styles.Benefit_Description}>
                演習だけでなく<b>脆弱性の原理に関わる技術の基礎</b>
                など、脆弱性について体系的に学べるコンテンツを提供しています。（堅牢化演習ではGo、Java、Ruby
                をはじめとした6言語に対応）
              </p>
              <p className={styles.Benefit_Description}>
                また、<b>GraphQLやJSON Web Token</b>
                のような、まだ世の中に対策情報の少ない技術のセキュリティのコンテンツも提供しています。
              </p>
            </div>
          </div>
          <div className={styles.Benefit_Item}>
            <img
              className={styles.Benefit_Image}
              src={data?.benefit3?.publicURL || ""}
              alt=""
            />
            <div className={styles.Benefit_Text}>
              <h3 className={styles.Benefit_ItemHeading}>
                1人からの導入も、初期費用不要。
                <br />
                <span className={styles.Benefit_Green}>低コスト</span>
                で今すぐに導入できます
              </h3>
              <p className={styles.Benefit_Description}>
                演習用のソフトウェア環境を準備する必要はありません。
                ブラウザとテキストエディタを用いてすぐに受講できます。
              </p>
              <p className={styles.Benefit_Description}>
                <b>初期費用も不要</b>
                なので、少人数での導入も低コストで可能です。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Interview}>
        <div className={styles.Interview_Article}>
          <h2 className={styles.Interview_Heading}>
            さまざまな開発チームで使われています
          </h2>
          <KenroVoiceList slugs={["cyberagent", "superstudio", "nikkei"]} />
          <div className={styles.Interview_ButtonArea}>
            <Link to="/kenro/cases" className={styles.CtaSection_Link}>
              <Button
                buttonTheme="Kenro"
                className={styles.Interview_Button}
                type="button"
                buttonSize="Large"
              >
                他の導入事例を見る
              </Button>
            </Link>
          </div>
        </div>
        <p className={styles.Interview_Description}>
          大規模な新人研修からスタートアップ企業のチーム演習まで、組織規模を問わずご活用いただいています。
          <span></span>
        </p>
        <div className={styles.Achievement}>
          <KenroAchievements />
        </div>
      </div>

      <div className={styles.Feature}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <h2 className={styles.Feature_Heading}>KENROの特徴的なコンテンツ</h2>
          <div className={styles.Feature_Challenges}>
            <div className={styles.Feature_ChallengeItem}>
              <img
                className={styles.Feature_Image}
                src={data?.hacking?.publicURL || ""}
                alt=""
              />
              <h3 className={styles.Feature_Title}>ハッキング演習</h3>
              <p className={styles.Feature_Description}>
                攻撃者の立場で、脆弱な
                <span className={styles.Feature_Bold}>
                  Webアプリケーションを実際に攻撃する
                </span>
                演習です。
              </p>
              <p className={styles.Feature_Description}>
                演習を通して攻撃者の立場を経験することで、開発時にセキュリティを意識することの重要性や、脆弱性の危険性を体験できます。
              </p>
            </div>
            <div className={styles.Feature_ChallengeItem}>
              <img
                className={styles.Feature_Image}
                src={data?.defence?.publicURL || ""}
                alt=""
              />
              <h3 className={styles.Feature_Title}>堅牢化演習</h3>
              <p className={styles.Feature_Description}>
                実際の開発現場に近いソースコードを用いて、脆弱な
                <span className={styles.Feature_Bold}>
                  Webアプリケーションを修正する
                </span>
                演習です。
              </p>
              <p className={styles.Feature_Description}>
                独自のジャッジシステムによって、修正されたコードに対して自動でテストが実行され、脆弱性が修正されたかどうかを自動で判定します。
                <span className={styles.Feature_Patent}>
                  （特許第7463021号）
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Content}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>
                基礎的な脆弱性に関するコンテンツ
              </h3>
              <p className={styles.Content_Description}>
                OWASP Top 10
                中に含まれる脆弱性をはじめ、Webアプリケーションにおける代表的な脆弱性を網羅的に学ぶことが可能です。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>SQL Injection</li>
                <li className={styles.Content_Item}>
                  Cross-Site Scripting (XSS)
                </li>
                <li className={styles.Content_Item}>
                  Cross-Site Request Forgery (CSRF)
                </li>
                <li className={styles.Content_Item}>OS Command Injection</li>
                <li className={styles.Content_Item}>Directory Traversal</li>
                <li className={styles.Content_Item}>
                  Insecure Deserialization
                </li>
                <li className={styles.Content_Item}>
                  XML eXternal Entity (XXE)
                </li>
                <li className={styles.Content_Item}>Open Redirection</li>
                <li className={styles.Content_Item}>Clickjacking</li>
                <li className={styles.Content_Item}>Header Injection</li>
              </ul>
            </div>
            <div className={styles.Content_Wrapper}>
              <h3 className={styles.Content_Title}>発展的なコンテンツ</h3>
              <p className={styles.Content_Description}>
                ご要望に応じてコンテンツを随時追加しています。
              </p>
              <ul className={styles.Content_List}>
                <li className={styles.Content_Item}>
                  GraphQLの基礎とセキュリティ
                </li>
                <li className={styles.Content_Item}>JWTの基礎とセキュリティ</li>
                <li className={styles.Content_Item}>
                  WebSocketの基礎とセキュリティ
                </li>
              </ul>
            </div>
            <Link to="/kenro/feature" className={styles.CtaSection_Link}>
              <Button
                buttonTheme="Kenro"
                className={styles.Interview_Button}
                type="button"
                buttonSize="Large"
              >
                学習の流れを詳しくみる
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.Vendor}>
        <div className={styles.KenroCommon2PaneSectionLayout}>
          <div className={styles.Vendor_Ribbon}>
            <img
              className={styles.Vendor_RibbonEdgeBefore}
              src={data?.ribbon_edge?.publicURL || ""}
              alt=""
            />
            開発・運営チームの紹介
            <img
              className={styles.Vendor_RibbonEdgeAfter}
              src={data?.ribbon_edge?.publicURL || ""}
              alt=""
            />
          </div>
          <h2 className={styles.Vendor_Heading}>
            セキュリティ診断の実績豊富な
            <br />
            プロフェッショナルが開発・運営しています
          </h2>
          <div className={styles.Vendor_Container}>
            <div className={styles.Vendor_Item}>
              <h3 className={styles.Vendor_SubHeading}>運営会社</h3>
              <h4 className={styles.Vendor_LogoWrapper}>
                <img
                  className={styles.Vendor_Logo}
                  src={data?.flatt_security_logo?.publicURL || ""}
                  alt="Flatt Securityロゴ"
                />
              </h4>
              <p className={styles.Vendor_Description}>
                <Link className={styles.Vendor_LinkToTop} to="/">
                  Flatt Security
                </Link>
                はWebアプリケーションからクラウド・IoTまで幅広くセキュリティ診断サービスを提供するセキュリティのプロフェッショナル企業です。
              </p>
              <p className={styles.Vendor_Description}>
                様々なお客様のサービスを診断した知見を活かし、セキュア開発コンサルティングサービスやKENROの提供を行っています。
              </p>
            </div>
            <div className={styles.Vendor_Item}>
              <h3 className={styles.Vendor_SubHeading}>コンテンツ監修</h3>
              <div className={styles.Vendor_Takashi}>
                <img
                  className={styles.Vendor_TakashiPhoto}
                  src={data?.takashi?.publicURL || ""}
                />
                <div>
                  <p>Flatt Security CTO</p>
                  <h4>米内 貴志</h4>
                </div>
              </div>
              <p className={styles.Vendor_Description}>
                株式会社Flatt Security
                CTO。一般社団法人セキュリティ・キャンプ協議会やSECCON実行委員会の一員として、情報セキュリティ技術の教育活動を行っています。
              </p>
              <p className={styles.Vendor_Description}>
                著書「Webブラウザセキュリティ ―
                Webアプリケーションの安全性を支える仕組みを整理する」
              </p>
            </div>
          </div>
        </div>
      </div>
    </Kenro2PaneLayout>
  );
};

export const query = graphql`
  query KenroPage {
    languages: file(
      relativePath: { eq: "kenro/new_grads_training/languages.svg" }
    ) {
      publicURL
    }
    logo_color: file(relativePath: { eq: "kenro/logo_color.svg" }) {
      publicURL
    }
    flatt_security_logo: file(relativePath: { eq: "flatt_security_logo.svg" }) {
      publicURL
    }
    firstview_background: file(
      relativePath: { eq: "kenro/firstview_background.jpg" }
    ) {
      publicURL
    }
    firstview_mock: file(relativePath: { eq: "kenro/firstview_mock.png" }) {
      publicURL
    }
    about: file(relativePath: { eq: "kenro/about.png" }) {
      publicURL
    }
    benefit1: file(relativePath: { eq: "kenro/benefit1.svg" }) {
      publicURL
    }
    benefit2: file(relativePath: { eq: "kenro/benefit2.svg" }) {
      publicURL
    }
    benefit3: file(relativePath: { eq: "kenro/benefit3.svg" }) {
      publicURL
    }
    hacking: file(relativePath: { eq: "kenro/hacking.png" }) {
      publicURL
    }
    defence: file(relativePath: { eq: "kenro/defence.png" }) {
      publicURL
    }
    takashi: file(relativePath: { eq: "kenro/takashi.jpg" }) {
      publicURL
    }
    ribbon_edge: file(relativePath: { eq: "kenro/ribbon_edge.svg" }) {
      publicURL
    }
    ogp: file(relativePath: { eq: "kenro/ogp.png" }) {
      publicURL
    }
  }
`;

export default Component;
